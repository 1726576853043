

.feeds-card-apl-animemo{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 50vh;
    border-radius: 6px;
    background-color: var(--bg);
    margin-bottom: 20px;

    .header-feeds{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: calc(100% - 14px);
        padding: 7px;
        margin-bottom: 7px;

        .foto-profil{
            width: 26px;
            height: 26px;
            border-radius: 12.5px;
            margin-right: 6px;
            object-fit: contain;
        }

        .info-pengguna{
            display: flex;
            flex-direction: column;
            width: calc(100% - (26px + 6px) - (16px + 6px));
            align-items: flex-start;

            .nama-pengguna{
                font-size: 13.5px;
                font-weight: 700;
                color: var(--warna-teks);
                margin: 0;
                margin-bottom: 2px;

                span{
                    font-size: 12px;
                    font-weight: 500;
                    color: var(--teks-tak-menyala)
                }
            }
            .tanggal-pos{
                font-size: 12.75px;
                font-weight: 500;
                margin: 0;

            }
        }

        .tbl-hapus{
            width: 20px;
            height: 20px;
            object-fit: contain;
            &:hover{
                cursor: pointer;
            }
        }
    }

    .kontainer-gbr-feeds{
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        .isi-feeds{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .aksi-bawah-feeds{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 7px;
        width: calc(100% - 14px);
        align-items: flex-start;

        .aksi-feeds{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 0 0 7px 0;
            height: fit-content;
    
            .suka-feeds{
                margin-right: 12px;
            }
    
            .gbr-aksi{
                width: 26px;
                height: 26px;
                object-fit: contain;
                &:hover{
                    cursor: pointer
                }
            }
        }
    
        .suka-komentar-jumlah{
            font-size: 12px;
            font-weight: 400;
            color: var(--teks-tak-menyala-terang);
            margin: 0;
            margin-bottom: 5px;
        }
    
        .nama-isi-feeds{
            font-weight: 500;
            font-size: 15px;
            margin: 0;
            margin-bottom: 2px;
            color: var(--warna-teks);
            word-break: break-all;
            text-align: justify;
        }

        .lihat-selengkapnya{
            font-size: 12px;
            font-weight: 400;
            color: var(--teks-tak-menyala);
            &:hover{
                cursor: pointer;
            }
        }
    }
}